.tile {
  position: relative;
  display: flex;
  width: 13vw;
  height: 13vw;
  box-sizing: border-box;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-radius: 5%;

  .color {
    height: 50%;
    margin-top: 12%;
  }

  .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-right: 7%;
    margin-left: 7%;
    font-size: 1vw;
    font-weight: 700;
    text-align: center;
  }

  .icon {
    position: absolute;
    top: 5vw;
    left: 8.5vw;
    height: 8vw;
    transform: translate(-50%, -50%);
  }

  &.has-background-icon {
    border-style: none;

    .title {
      margin-right: 9%;
      margin-left: 9%;
      color: #496b6e;
    }

    .background-icon {
      height: 62%;
      margin-left: 1vw;
      background-repeat: no-repeat;
      background-size: contain;

      &.no-icon {
        margin: 0;
        background-position: center;
      }
    }
  }
}
