.header {
  display: flex;
  width: 100%;
  height: 10vw;
  max-height: 10vh;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  img {
    height: 80%;
  }
}
