.category {
  padding-right: 2vw;
  padding-left: 2vw;

  .category-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5vw;
    border-color: #000;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 0.5vw;
    color: #496b6e;
    font-size: 2vw;
    font-weight: 700;

    .dropdown-icon {
      margin-top: 1vw;
      transform: rotate(180deg);
      transition-duration: 0.5s;
      transition-property: transform;
      transition-timing-function: ease-out;
    }
  }

  &.open .dropdown-icon {
    transform: rotate(0deg);
  }

  .section-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
    margin: 2vw;
    gap: 2vw;
    transition-duration: 0.5s;
    transition-property: max-height;
    transition-timing-function: ease-out;
  }
}
