@font-face {
  font-family: "'Century Gothic'";
  src: url(../../fonts/CenturyGothic.ttf);
}

.app {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "'Century Gothic'", sans-serif;
}
