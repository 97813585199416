.menu {
  display: flex;
  overflow: auto;
  width: 100%;
  flex: 1;
  flex-direction: column;
  scrollbar-gutter: stable;
}

::-webkit-scrollbar {
  width: 3vw;
}

::-webkit-scrollbar-track {
  border: 1.2vw solid transparent;
  border-radius: 2vw;
  background: #7d9e9d;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
  border: 0.7vw solid transparent;
  border-radius: 2vw;
  background-clip: padding-box;
  background-color: #ccd7d2;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aeb8b3;
}
