.section {
  width: 100%;

  .section-title {
    padding-bottom: 1vw;
    border-color: #000;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-right: 20vw;
    margin-bottom: 2vw;
    margin-left: 20vw;
    font-size: 2vw;
    font-weight: 700;
    text-align: center;
  }

  .tile-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2vw;
  }
}
