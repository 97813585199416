.footer {
  display: flex;
  width: 100%;
  height: 6vw;
  max-height: 6vh;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #496b6e;

  img {
    height: 60%;
    margin-right: 4vw;
  }
}
