.content-container {
  display: flex;
  width: 92vw;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: 2vw;
  padding-bottom: 2vw;

  .content {
    max-height: 68vw;
    flex: 1;
  }
}
