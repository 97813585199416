.content-footer {
  display: flex;
  width: 100%;
  height: 6vw;
  max-height: 6vh;
  align-items: center;
  justify-content: space-between;
  background-color: #496b6e;

  .icon {
    width: 3vw;
    max-width: 3vh;
    height: 3vw;
    max-height: 3vh;
    margin-left: 1vw;
    color: #fff;
  }

  img {
    height: 60%;
    margin-right: 4vw;
  }
}
